@import '../../../../styles/abstracts/variables'
@import '../../../../styles/abstracts/helpers'

.productdetails-1
    padding: 4% 9%

.productdetails-2
    margin-top: 80px

.productdetails-3
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    +md
        flex-direction: column
        align-items: flex-start
        gap: 10px

.productdetails-span-1
    font-size: 24px
    font-weight: 700
    line-height: 29px
    cursor: pointer

.productdetails-span-2
    font-size: 24px
    font-weight: 400
    line-height: 29px

.productdetails-4
    display: flex
    align-items: center
    gap: 6px

.productdetails-span-3
    font-size: 16px
    font-weight: 700
    line-height: 19px

.productdetails-7
    margin-top: 75px
    width: 100%
    display: flex
    align-items: flex-start
    +md
        flex-direction: column

.productdetails-5
    width: 50%
    +md
        width: 100%

.productdetails-6
    width: 50%
    padding: 12px
    +md
        width: 100%

.productdetails-sub-1
    margin-top: 10px
    width: 100%
    max-width: 100%
    display: flex
    flex-direction: column
    // gap: 30px

.productdetails-sub-span-1
    font-size: 24px
    font-weight: 700
    line-height: 29px
    text-align: left
    color: $primary-color
    margin-bottom: 20px

.productdetails-sub-2
    margin-top: 15px
    width: 100%
    display: flex
    flex-direction: column
    // gap: 10px

.productdetails-sub-span-2-bold
    font-size: 16px
    font-weight: 700
    line-height: 19px
    text-align: justify
    color: $secondary-color

.productdetails-sub-span-2
    font-size: 16px
    font-weight: 400
    line-height: 19px
    text-align: justify
    color: $secondary-color

.productdetails-sub-3
    margin-top: 30px
    display: flex
    width: 100%
    +md
        flex-direction: column
        gap: 10px

.productdetails-sub3-right
    width: 50%
    display: flex
    flex-direction: column
    gap: 12px
    +md 
        width: 100%

.productdetails-sub3-left
    width: 50%
    display: flex
    flex-direction: column
    gap: 12px
    padding: 10px

.productdetails-8
    margin-top: 50px
    display: flex
    flex-direction: column
    gap: 30px

.productdetails-8-span1-bold
    font-size: 26px
    font-weight: 700
    line-height: 32px
    text-align: left

.productdetails-8-span1
    font-size: 16px
    font-weight: 400
    line-height: 19px
    text-align: center

.productdetails-8-sub
    display: flex
    gap: 5px
    align-items: center

