
@import '../../../styles/abstracts/variables'
@import '../../../styles/abstracts/helpers'

.services-container
    padding: 0px 18px 0px 18px
    // width: 100%
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    gap: 60px
    +sm
        gap: 30px

.know-more
    font-size: 16px
    font-weight: 700
    color: $primary-color
    cursor: pointer

.row-container
    width: 100%
    display: flex
    // flex-direction: column
    flex-wrap: wrap
    gap: 60px

.iconandtitle
    display: flex
    align-items: center
    flex-direction: column
    gap: 15px
    width: 20%
    +sm
        width: 48%

.row-1
    display: flex
    // justify-content: space-between
    width: 100%
    gap: 10px
    +md
        flex-wrap: wrap
        justify-content: space-between
    +sm
         display: flex
         flex-direction:column
         justify-content: center
         align-items: center
    

.icon-text
    font-size: 24px
    font-weight: 400
    text-align: center
    width: 74%
    line-height: 29px
    +sm
        font-size: 16px
        line-height: 20px

.row-2
    display: flex
    justify-content: center
    width: 100%
    padding: 0px 18px 0px 18px
    +sm
        flex-wrap: wrap
        justify-content: space-between

.iconandtitle-2
    display: flex
    align-items: center
    flex-direction: column
    gap: 15px
    width: 22%
    +sm
        width: 45%
