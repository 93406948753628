@import '../../styles/abstracts/variables'
@import '../../styles/abstracts/helpers'

.floating-menu
    position: fixed
    bottom: 20px
    right: 20px
    border: none
    // border-radius: 50%
    // width: 60px
    // height: 60px
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
    cursor: pointer
    transition: background-color 0.3s ease
    +md 
        bottom: 5%
        right: 5%
        width: 50px
        height: 50px
