@import '../../../styles/abstracts/variables'
@import '../../../styles/abstracts/helpers'

.detail-main-container
    margin-top: -95px

.product-detail-container
    height: 100%
    background-image: url('/assets/SVG/Right 1.svg'), url('/assets/SVG/Left 1.svg')
    background-position: 100% 0% , -100px center
    background-repeat: no-repeat
    background-size: auto
