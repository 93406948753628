@import '../../styles/abstracts/variables'
@import '../../styles/abstracts/helpers'

.carousel-container
    width: 100%
    // margin: 0 auto

.slide
    position: relative
    text-align: center
    color: white

    & img
        width: 100%
        height: auto

    &-text
        position: absolute
        top: 42%
        left: 38%
        transform: translate(-50%, -50%)
        z-index: 999
        +sm
            top: 40%
        +xs
            left: 35%
    &-text div
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: flex-start
        margin-top: 30px
        +sm
            margin-top: 15px

    &-text div h1
        font-size: 60px
        line-height: 80px
        font-weight: 800
        color: $text-color
        margin: 0
        +md
            font-size: 40px
            line-height: 50px
        +sm
            font-size: 20px
            line-height: 20px
        +xs
            font-size: 12px
            line-height: 10px

    &-text div h1 span
        font-size: 60px
        font-weight: 700
        +md
            font-size: 40px
        +sm
            font-size: 25px
            line-height: 20px
        +xs
            font-size: 15px
            line-height: 10px

    &-text div span
        margin-top: 10px
        font-size: 30px
        line-height: 52px
        font-weight: 400
        color: $text-color
        text-align: justify
        +md
            font-size: 24px
            line-height: 20px
        +sm
            font-size: 15px
            line-height: 20px
            margin-top: 15px
        +xs
            font-size: 12px
            // line-height: 10px

// .btn-container-carousel
//     margin-top: 20px
.overlay-container
    position: relative
    width: 100%
    height: 100vh
    +sm
        height: 250px
    & img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        object-fit: cover

// .overlay-container
//     position: relative
//     width: 720px
//     height: 100vh
//     background-color: black
//     display: flex
//     justify-content: center
//     align-items: center
//     overflow: hidden
//     & img
//         min-width: 100%
//         min-height: 100%
//         // object-fit: contain

.blurred-image
    filter: blur(2px)

.bright-image
    z-index: 2

.gradient-overlay
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: linear-gradient(rgba(15, 14, 14, 0.9), rgba(115, 115, 115, 0))
    z-index: 1

.slick-dots
    position: absolute
    bottom: 10px
    width: 100%
    display: flex !important
    justify-content: center
    padding: 0
    margin: 0
    list-style: none

    & li button:before
        font-size: 6px
        color: $primary-color
        opacity: 0.7px

    & li.slick-active button:before
        color: $primary-color
