@import '../../styles/abstracts/variables'
@import '../../styles/abstracts/helpers'

.background-container-products
    height: 100%
    background-image: url('/assets/SVG/Right 1.svg'), url('/assets/SVG/Left 1.svg')
    background-position: 1000px top , center left
    background-repeat: no-repeat
    background-size: auto
    margin-bottom: 35px
    +sm
        background: none

.whole-container-products
    margin-bottom: 35px
    margin-top: -95px

.product-categories-container
    margin-bottom: 30px
    margin-top: 30px

