
@import '../../../styles/abstracts/variables'
@import '../../../styles/abstracts/helpers'

.who-container
    width: 100%
    background: $primary-color-light

.who-content-1
    padding: 4% 9%

.content-divider
    width: 100%
    display: flex
    +sm
        flex-direction: column-reverse

.divider-1
    width: 50%
    +sm
        width: 100%

.divider-2
    width: 50%
    +sm
        width: 100%

.content-holder
    width: 95%
    display: flex
    flex-direction: column
    gap: 50px
    +sm
        width: 100%
        gap: 15px
        align-items: center

.who-are-we-title
    font-size: 51px
    font-weight: bold
    +sm
        font-size: 30px

.who-are-we-sub-title
    font-size: 24px
    font-weight: 400
    text-align: justify
    line-height: 30px
    +sm 
        font-size: 18px

.content-holder-2
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    gap: 40px

.read-more
    color: $primary-color

.img-style
    border-radius: 40px
    width: 500px
    +xl 
        width: 300px

.image-container
    width: 80%
    // display: contents
    +sm
        display: none
