@import '../../styles/abstracts/variables'
@import '../../styles/abstracts/helpers'

.services-0
    margin-bottom: 45px

.services-1
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 40px
    width: 100%
    +sm
        gap: 15px

.services-1-img
    width: 75%
    +sm 
        width: 90%

.services-card
    width: 100%
    padding: 0px 11%
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    +md
        padding: 0px 8%
    +sm
        flex-direction: column
        padding: 0px 0px

.services-1-card
    padding: 5%
    background: #fff
    border-radius: 24px
    box-shadow: 1px 1px 15.2px 0px #00000026
    transition: transform 0.3s ease
    position: relative
    min-height: 355px
    height: 100%
    +md 
        min-height: 435px
    +sm
        min-height: 320px

.services-1-card-container-new
    padding: 10px 20px
    box-sizing: border-box
    // max-width: 538px
    width: 50%
    +sm
        width: 100%
        max-width: 400px

.services-1-card-content-holder
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 25px

.services-1-span-heading
    font-size: 28px
    font-weight: 700
    line-height: 34px
    color: $secondary-color
    // width: 255.34px
    text-align: center

.services-1-span-desc
    font-size: 16px
    font-weight: 400
    line-height: 1.5
    color: $black
    text-align: justify
    width: 439px
    hyphens: auto
    word-spacing: 0.2em
    max-width: 100%
    margin: 10px 0px
    +sm
        width:100%

