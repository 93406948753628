@import '../../styles/abstracts/variables'
@import '../../styles/abstracts/helpers'

.table-container
    overflow-x: auto
    max-width: 100%
    border-spacing: 0
table
    width: 100%
    border-collapse: separate
    border-spacing: 0
    margin-bottom: 20px

    th, td
        height: 60px
        text-align: center
        font-size: 14px
        font-weight: 400
        line-height: 17px
        padding: 14px
        border: 1px solid $secondary-color

    th
        padding: 13px
        text-align: center
        font-size: 14px
        font-weight: 400
        line-height: 17px

tr:first-child th:first-child
    border-top-left-radius: 10px

tr:last-child td:first-child
    border-bottom-left-radius: 10px

tr:first-child th:last-child
    border-top-right-radius: 10px

tr:last-child td:last-child
    border-bottom-right-radius: 10px
