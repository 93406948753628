@import '../../../styles/abstracts/variables'
@import '../../../styles/abstracts/helpers'

.expertise-1
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    gap: 30px

.expertise-2
    width: 909px
    display: flex
    flex-direction: column
    gap: 25px
    max-width: 100%
    +sm
        width: 100%

.expertise-3
    width: 100%
    display: flex
    align-items: center
    gap: 45px
    text-align: justify
    +sm
        flex-direction: column
        gap: 30px

.expertise-3-span
    font-size: 16px
    font-weight: 400
    line-height: 20px
