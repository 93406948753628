@import '../../../styles/abstracts/variables'
@import '../../../styles/abstracts/helpers'

.humble-1
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column

.container-humble
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    gap: 20px
    margin-top: 100px

.container-sub-humble
    width: 550px
    text-align: center
    max-width: 100%
    margin-top: 4%

.image-1-container
    width: 100%

.humble-img
    width: 900px
    height: 383px
    max-width: 100%
    +sm
        width: 85%
        height: 100%

.container-content
    width: 1045px
    text-align: justify
    max-width: 100%
    +sm
        width: 85%

.container-content-2
    font-size: 16px
    font-weight: 400
    line-height: 20px
