@import '../../styles/abstracts/variables'
@import '../../styles/abstracts/helpers'

/* MultiRowCarousel.sass */
.slider-container-old
    width: 100%
    margin: 0 auto
    padding: 20px 0

.carousel-item-old
    padding: 10px
    box-sizing: border-box
    outline: none

.carousel-image-old
    width: 336px
    max-width: 100%
    height: auto
    object-fit: cover
    border: none
    outline: none
    border-radius: 28px
    display: inline !important
    cursor: pointer

.slick-slide-old
    outline: none !important

.slider-2-old .slick-dots
    position: absolute
    width: 100%
    display: flex !important
    justify-content: center
    padding: 0
    margin: 0
    list-style: none
    bottom: -20px
    & li button:before
        font-size: 6px
        color: $primary-color
        opacity: 0.7px
    & li.slick-active button:before
        color: $primary-color

