@import '../../../styles/abstracts/variables'
@import '../../../styles/abstracts/helpers'

.weoffer-1
    width: 100%
    display: flex
    align-items: center
    justify-content: center

.weoffer-2
    margin-top: 4%