@import '../../styles/abstracts/variables'
@import '../../styles/abstracts/helpers'

.navbar
  position: sticky
  top: 0
  background: transparent
  display: flex
  align-items: center
  z-index: 1000
  transition: background-color 0.3s
  &.scrolled
    background: white
  +sm
    display: none

.navbar-logo
  width: 20%

.navbar-nav
  list-style: none
  width: 75%
  flex-direction: row
  display: flex
  justify-content: space-between
  margin-right: 20%
  +md 
    margin-right: 0%

.nav-link
  font-size: 16px
  font-weight: 500
  line-height: 19px
  color: $secondary-color
  &.active:hover
    color: $primary-color !important
  &.active
    color: $primary-color
    font-weight: 900

.navbar-container
  width: 85%
  margin: auto 5%
  padding: 1% 2% 0% 2%
  display: flex
  align-items: baseline

.mobile
  display: none
  +sm
    display: flex

.top-nav
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  // background: linear-gradient(to right, #FBF5F7,$primary-color)
  background: transparent
  color: #FFF
  width: 100%
  padding: 1em

.menu
  display: flex
  flex-direction: row
  list-style-type: none
  margin: 0
  padding: 0

  & > li
    margin: 0 1rem
    overflow: hidden

  &-button-container
    display: none
    height: 100%
    width: 30px
    cursor: pointer
    flex-direction: column
    justify-content: center
    align-items: center

#menu-toggle
  display: none

.menu-button,
.menu-button::before,
.menu-button::after
  display: block
  // background-color: #000000ab
  background-color: $primary-color
  position: absolute
  height: 4px
  width: 30px
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1)
  border-radius: 2px

.menu-button::before
  content: ''
  margin-top: -8px

.menu-button::after
  content: ''
  margin-top: 8px

#menu-toggle:checked + .menu-button-container .menu-button::before
  margin-top: 0px
  transform: rotate(405deg)

#menu-toggle:checked + .menu-button-container .menu-button
  background: rgba(255, 255, 255, 0)

#menu-toggle:checked + .menu-button-container .menu-button::after
  margin-top: 0px
  transform: rotate(-405deg)

@media (max-width: 700px)
  .menu-button-container
    display: flex

  .menu
    position: absolute
    top: 25px
    margin-top: 50px
    left: 0
    flex-direction: column
    width: 100%
    justify-content: center
    align-items: center
    z-index: 99

  #menu-toggle ~ .menu li
    height: 0
    margin: 0
    padding: 0
    border: 0
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1)

  #menu-toggle:checked ~ .menu li
    // border: 1px solid #333
    height: 2.5em
    padding: 0.5em
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1)

  .menu > li
    display: flex
    justify-content: center
    margin: 0
    padding: 0.5em 0
    width: 100%
    color: white
    background-color: white
    &:not(:last-child)
      border-bottom: 1px solid #444
