@import './styles/main'

body
    margin: 0

::-webkit-scrollbar
    width: 5px
    height: 4px

::-webkit-scrollbar-thumb
    background-color: $primary-color
    border-radius: 6px

::-webkit-scrollbar-track
    background: #f1f1f1
