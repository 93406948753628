@import '../../styles/abstracts/variables'
@import '../../styles/abstracts/helpers'

.footer
    width: 100%
    background-color: $primary-color-light
    border-radius: 65px 65px 0px 0px

.footer-whole
    // padding: 5% 8%
    padding: 4% 8% 1% 8%
    display: flex
    flex-direction: column

.footer-sub
    width: 100%
    display: flex
    margin-bottom: 10%
    +sm
        flex-direction: column
        margin-bottom: 15%

.footer-sub-right
    width: 50%
    display: flex
    justify-content: flex-end
    +sm
        width: 100%
        justify-content: flex-start

.footer-sub-left
    width: 50%
    display: flex
    +sm
        width: 100%
        margin-bottom: 5%

.footer-copy
    width: 100%
    display: flex
    flex-direction: column
    gap: 10px

.line
    border-top: 1px solid #8c8b8b

.footer-rights
    display: flex
    align-items: center
    justify-content: space-between
    +sm
        flex-direction: column

.footer-left-content
    width: 100%
    display: flex
    flex-direction: column
    justify-content: flex-start
    gap: 25px

.footer-logo
    width: 76px

.company-name
    font-weight: 700
    font-size: 18px
    line-height: 22px

.company-rights-name
    font-weight: 400
    font-size: 18px
    line-height: 22px
    +sm 
        font-size: 16px

.request-form-title
    margin: 0
    font-size: 40px
    font-weight: 700
    +sm
        font-size: 32px

.footer-left-span-content
    width: 86%
    font-size: 25px
    font-weight: 400
    text-align: justify
    +sm
        width: 100%
        font-size: 16px

.footer-social-medias
    display: flex
    flex-direction: column
    gap: 15px

.social-medias-container
    display: flex
    gap: 3%
    align-items: baseline

.company-address
    width: 50%
    +sm 
        width: 75%

.social-medias-span
    font-size: 16px
    font-weight: 400
    +sm
        font-size: 14px

.footer-right-content
    display: flex
    flex-direction: column
    justify-content: flex-start
    gap: 20px

.button-container
    margin-top: 20px
    width: 100%
    display: flex
    justify-content: center

.footer-form
    display: flex
    flex-direction: column
    gap: 25px
    align-items: center
