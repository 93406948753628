@import '../../styles/abstracts/variables'
@import '../../styles/abstracts/helpers'

.card-carousel-container
    width: 90%
    margin: 0 auto

.carousel-caption-text-new
    position: relative
    text-align: center
    display: flex !important
    justify-content: center
    align-items: center
    +sm
        margin-left:12px

.carousel-caption-holder
    width: 75%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    & img
        width: 100%
        height: 213px
        display: block
        margin: 0 auto

.carousel-caption-heading-text
    display: block
    // margin-top: 10px
    font-size: 16px
    font-weight: 700
    color: $secondary-color
    text-align: center
+sm    
    .slick-arrow.slick-prev.custom-arrow 
        left: -10px
                