@import '../../styles/abstracts/variables'
@import '../../styles/abstracts/helpers'
    
.card-carousel-container-text
    width: 90%
    margin: 0 auto
    +sm
        width: 80%

.card-text-new
    padding: 25px
    background: #fff
    border-radius: 24px
    box-shadow: 1px 1px 15.2px 0px #00000026
    transition: transform 0.3s ease
    position: relative
    min-height: 320px

.card-container-text-new
    position: relative
    text-align: center

.card-content-text-holder
    position: relative
    & img
        width: 100%
        height: auto
        cursor: pointer
    +sm
    width:80%
    +lg
    width:100%
    +md 
    width:100%

.span-heading-text
    cursor: pointer
    position: absolute
    bottom: 55px
    left: 50%
    transform: translateX(-50%)
    color: white
    padding: 5px 10px
    border-radius: 5px
    font-size: 18px
    font-weight: 700
    line-height: 29px
    +sm
        font-size: 18px

.span-sub-heading-text
    cursor: pointer
    position: absolute
    bottom: 20px
    left: 50%
    transform: translateX(-50%)
    color: white
    padding: 5px 10px
    border-radius: 5px
    font-size: 16px
    font-weight: 700
    line-height: 29px
    width: 100%

// To hide default side arrow
.slick-prev:before,
.slick-next:before
    content: ''
