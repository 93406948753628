// Media queries
=lg
    @media only screen and (max-width: 1536px)
        @content
=xl
    @media only screen and (max-width: 1200px)
        @content
=md
    @media only screen and (max-width: 900px)
        @content
=sm
    @media only screen and (max-width: 600px)
        @content
=xs
    @media only screen and (max-width: 425px)
        @content
