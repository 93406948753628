
@import '../../../styles/abstracts/variables'
@import '../../../styles/abstracts/helpers'

.product-category-container
    width: 100%
    background: $primary-color-light
    padding-bottom: 30px

.product-category-content-1
    padding: 2% 8%
    +sm
        padding: 5%

.category-divider-1
    width: 100%

.category-holder
    display: flex
    flex-direction: column
    align-items: center
    gap: 25px

.category-holder-2
    width: 100%
    +sm
        width: 85%

.product-categoriex-span-para
    font-size: 16px
    font-weight: 400
    line-height: 20px
    text-align: justified
