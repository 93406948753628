@import '../../../styles/abstracts/variables'
@import '../../../styles/abstracts/helpers'

.product-container-old
    width: 100%
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    +sm
        text-align: center

.desc-container
    width: 100%
    padding: 4% 8%
    text-align: justify

.product-desc
    font-size: 24px
    font-weight: 400
    line-height: 30px
    +sm
        font-size: 18px

.multi-caro-container
    width: 800px
    max-width: 100%
    min-width: 100%

.btn-container
    display: flex
    justify-content: center
    margin-top: 20px
    margin-bottom: 25px
