@import '../../../styles/abstracts/variables'
@import '../../../styles/abstracts/helpers'

.primary-button
    background-color: $primary-color
    width: 156px
    height: 47px
    // position: absolute
    gap: 0
    border-radius: 25px
    opacity: 1
    border: none
    color: white
    font-size: 16px
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    &:hover
        opacity: 0.8
    +sm
        width: 100px
        height: 38px
        font-size: 14px
    +xs
        width: auto
        flex-wrap: wrap
        font-size: 12px
        padding: 8px
