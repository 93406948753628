@import '../../styles/abstracts/variables'
@import '../../styles/abstracts/helpers'

.background-container
    height: 100%
    background-image: url('/assets/SVG/Right 1.svg'), url('/assets/SVG/Left 1.svg')
    background-position: 1000px top , center left
    background-repeat: no-repeat
    background-size: auto
    +sm
        background: none

.whole-container
    margin-bottom: 35px
    margin-top: -95px

.expertise-container
    padding: 20px
    margin-bottom: 25px

.weoffer-container
    margin-bottom: 45px