@import '../abstracts/variables'
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap')

@font-face
  font-family: 'Century Gothic'
  src: url('../../fonts/CenturyGothic.ttf') format('truetype')

// Font family definitions
$font-primary: 'Century Gothic'
$font-secondary: 'Georgia, serif'

body
  font-family: $font-primary
  line-height: 1.6
  color: $text-color

h1, h2, h3, h4, h5, h6
  font-family: $font-primary
  margin-bottom: 1rem
  line-height: 1.2

h1
  font-size: 2.5rem
  +sm
    font-size: 2rem

h2
  font-size: 2rem
  +sm
    font-size: 1.75rem

h3
  font-size: 1.75rem
  +sm
    font-size: 1.5rem

h4
  font-size: 1.5rem
  +sm
    font-size: 1.25rem

h5
  font-size: 1.25rem
  +sm
    font-size: 1rem

h6
  font-size: 1rem
  +sm
    font-size: .5rem

p
  margin-bottom: 1rem

a
  color: $link-color
  text-decoration: none
  &:hover
    color: $link-color !important
    text-decoration: none

// Utility classes for text
.text-center
  text-align: center

.text-right
  text-align: right

.text-left
  text-align: left

.font-bold
  font-weight: bold

.font-light
  font-weight: 300

.font-italic
  font-style: italic

// Common Styles
.text-title
  font-size: 44px
  font-weight: 900
  line-height: 54px
  letter-spacing:0.5px
  +sm
    font-size: 24px
    font-weight: 700

