@import '../../../styles/abstracts/variables'
@import '../../../styles/abstracts/helpers'

.poducts-1
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column

.container-poducts
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    gap: 20px

.container-sub-poducts
    width: 550px
    text-align: center
    max-width: 100%
    margin-top: 100px


.category-container-2
    width: 100%
    +sm 
        width: 85%
