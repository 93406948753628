.dropdown
    position: relative
    display: inline-block

.selected-option-position
    width: 100%
    margin-left: 12px
    
.selected-option
    cursor: pointer
    padding: 10px
    border: 1px solid #ccc
    border-radius: 5px
    min-width: 100%
    width: 300px
    position: relative
    display: flex
    justify-content: space-between
    align-items: center
    font-size: 12px
    font-weight: 400
    .arrow
        position: absolute
        top: 50%
        right: 10px
        transform: translateY(-50%)

.up
    transform: translateY(-50%) rotate(-180deg)

.down
    transform: translateY(-50%)

.options
    position: absolute
    top: 100%
    left: 0
    background-color: #fff
    border: 1px solid #ccc
    border-top: none
    border-radius: 0 0 5px 5px
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1)
    width: 100%
    z-index: 9999

    .search-container
        padding: 10px
        border-bottom: 1px solid #ccc

    input[type="text"]
        width: calc(100% - 20px)
        padding: 8px
        border-radius: 5px
        border: 1px solid #ccc
        outline: none

    ul
        list-style-type: none
        padding: 0
        margin: 0

    .option
        padding: 10px
        cursor: pointer

        &:hover
            background-color: #f4f4f4

        &.selected
            background-color: blue
            color: white
