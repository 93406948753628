label.input-group__label.c {
    /* position: absolute; */
    position: absolute;
    top: -0.8em;
    left: 2.75em;
    padding: 0 0.25em;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.1em;
    color: rgba(0, 0, 0, 0.6);
    text-transform: capitalize;
    background: white;
    z-index: 1;
    align-items: center;
}

.colored-background {
    background-color:#fbf5f7 !important;
  }

.input-group.c {
    position: relative;
    width: 80%;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    z-index: 2;
    align-items: center;
}

.input-group__input.c {
    position: relative;
    font-size: 12px;
    line-height: 1;
    border-style: none;
    outline: none;
    height: 50px;
    width: 90%;
    align-items: center;
    padding: 0.4em 1em 0.4em 1em;
    border: 1.2px solid #000000;
    border-radius: 12px;
    transition: background-position 0.8s ease-out;
    background: none;
    resize: none;
}

.request-form-titles {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
}

.contact-info {
    font-size: 20px;
    font-weight: 400;
    text-align: justify;
    width: 90%;
}

ul.emp {
    margin-top: 8%;
}

.footer-forms {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    margin-top: 6%;
}

.calb {
    border-left: 1px solid;
}

.ifrmap {
    width: 100%;
    margin-top: 2%;
    border-radius: 15px;
    box-shadow: 1px 1px 10.5px 2px rgba(0, 0, 0, 0.1);
}

.contactusm {
    /* padding: 10%; */
}

.banercont {
    width: 100%;
}

.contactusm {
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 15px;

    padding: 20px;
    width: 80%;
    /* Adjust the width as needed */
    max-width: 1200px;
    /* Maximum width to limit the size on larger screens */
}

.sncnm {
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 5%;
}

.sncnm {
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 5%;
    padding: 5%;
    border-radius: 25px;
}

.conatcthti {
    text-align: center;
    color: white;
    font-weight: 600;
}

.tksucmsg {
    font-size: 35px;
    font-weight: 600;
}

.nmbem {
    text-decoration: none;
}

.nmbem:hover {
    text-decoration: none;
    color: unset;
}

.fotercont {}

.contact-5 {
    height: 100vh
}

@media (min-width: 800px) and (max-width: 1104px) {
    .contact-5 {
        height: 110vh;
    }

    .contactusm {
        /* top: 150%; */
        top: 100%
    }

    .input-group__input.c {
        width: 100%;
    }

    label.input-group__label.c {
        left: 1.75em;
    }

    .footer-forms {
        margin-top: 8%;
    }

    .request-form-titles {
        font-size: 30px;
    }

    .ifrmap {
        margin-top: 8%;
    }
}

@media (min-width: 600px) and (max-width: 780px) {
    .contact-5 {
        height: 100%;
    }

    .contactusm {
        position: unset;

        transform: unset;

        border-radius: 15px;

        padding: 20px;
        width: 100%;
        /* Adjust the width as needed */
        max-width: 100%;
        /* Maximum width to limit the size on larger screens */
    }

    .calb {
        border-left: none;
    }

    .conatcthti {
        color: rgb(132, 38, 38);
    }

    .contact-form {
        margin-top: 6%;
    }

    .ifrmap {
        margin-top: 7%;
    }
}

@media (min-width: 399px) and (max-width: 589px) {
    .contact-5 {
        height: 100%;
    }

    .contactusm {
        position: unset;

        transform: unset;

        border-radius: 15px;

        padding: 20px;
        width: 100%;
        /* Adjust the width as needed */
        max-width: 100%;
        /* Maximum width to limit the size on larger screens */
    }

    .calb {
        border-left: none;
    }

    .conatcthti {
        color: rgb(132, 38, 38);
    }

    .contact-form {
        margin-top: 6%;
    }

    .ifrmap {
        margin-top: 7%;
    }

    .input-group__input.c {
        width: 100%;
    }

    label.input-group__label.c {
        left: 1.75em;
    }

    .request-form-titles {
        font-size: 20px;
    }
}

@media (min-width: 282px) and (max-width: 399px) {
    .contact-5 {
        height: 100%;
    }

    .contactusm {
        position: unset;

        transform: unset;

        border-radius: 15px;

        padding: 20px;
        width: 100%;
        /* Adjust the width as needed */
        max-width: 100%;
        /* Maximum width to limit the size on larger screens */
    }

    .calb {
        border-left: none;
    }

    .conatcthti {
        color: rgb(132, 38, 38);
    }

    .contact-form {
        margin-top: 6%;
    }

    .ifrmap {
        margin-top: 7%;
    }

    .input-group__input.c {
        width: 100%;
    }

    label.input-group__label.c {
        left: 1.75em;
    }

    .request-form-titles {
        font-size: 20px;
    }
}

.company-address2 {
    width: 55%;
}

.map-container {
    margin-top: 20px;
}