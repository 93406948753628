@import '../../../styles/abstracts/variables'
@import '../../../styles/abstracts/helpers'

.secondary-button
    background-color: $secondary-color
    width: 156px
    height: 47px
    // position: absolute
    gap: 0
    border-radius: 25px
    opacity: 1
    border: none
    color: white
    font-size: 16px
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    font-weight: 700
    &:hover
        opacity: 0.8
    +xs
        width: 100%
        flex-wrap: wrap
