@import '../../styles/abstracts/variables'
@import '../../styles/abstracts/helpers'

.image-gallery
    display: flex
    justify-content: space-between
    align-items: stretch
    +md
        flex-direction: column

.main-image
    flex: 1
    max-width: 70%
    +md
        max-width: 100%
    img
        width: 100%
        height: 400px
        object-fit: cover
        border-radius: 36px

.thumbnails
    flex: 1
    max-width: 30%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    +md
        max-width: 100%
        flex-direction: row
        justify-content: center
        gap: 5px
    img
        cursor: pointer
        width: 124px
        height: 124px
        border-radius: 10px
        margin-bottom: 10px
