.card-container-text-new {
    position: relative;
    cursor: pointer;
    text-align: center;
}

.card-container-text-new img {
    width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.card-content-text-holder {
    position: relative;
}

.card-container-text-new {
    position: relative;
    cursor: pointer;
    text-align: center;
}

.card-container-text-new img {
    width: 100%;
    height: auto;
    display: block;
}

.card-content-text-holder {
    position: relative;
}

.overlay {
    position: absolute;
    top: 160px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    /* Black overlay with 50% opacity */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    opacity: 0.7;
}

.span-heading-text {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    width: 80%;
    margin-bottom: 1rem; 
}

.span-sub-heading-text {
    font-size: 1rem;
    width: 80%;
    margin-top: 0.5rem;
}

/* 
.span-heading-text {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.span-sub-heading-text {
    font-size: 1rem;
} */
