.bigimgfi {
  width: 100%;
}

.firstsectImg {
  width: 100%;
  padding: 10px;
}

.subclimg {
  width: 95%;
  padding: 5%;
}

.bigisk {
  padding: 2%;
}


.imgsidesaa {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.slider-content {
  padding: 20px;
}

.gallery-slick-dots.gallery-slick-thumb {
  display: flex !important;
  justify-content: center;
  bottom: 0 !important;
}

.gallery-slick-dots.gallery-slick-thumb li {
  margin: 0 5px;
}

.gallery-slick-dots.gallery-slick-thumb li .gallery-custom-dot {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
}

.gallery-slick-dots.gallery-slick-thumb li.slick-active .gallery-custom-dot {

  color: red;
}

.gallery-custom-arrow {
  position: absolute;
  top: 99%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  font-size: 24px;
  color: #000;
  padding: 2px 10px;
  -webkit-user-select: none;
  user-select: none;
  padding-top: 6px;
}

.gallery-custom-arrow-next {
  right: 42%;
  padding-top: 12px;
}

.gallery-custom-arrow-prev {
  left: 44%;
  padding-top: 12px;
}

.gallery-slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.gallery-slick-dots li {
  list-style: none;
}

.gallery-slick-dots li button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.gallery-slick-dots li.slick-active button {
  color: red;
}

.gallery-slick-dots li button:before {
  content: attr(data-role);
  color: #000;
}

.gallery-custom-dot {
  color: #000;
  font-size: 16px;
  /* margin: 0 5px; */
  display: flex;
  justify-content: flex-start;
}

@media (min-width: 600px) and (max-width: 780px) {
  .sideimgsml {
    width: 100%;
  }
  .gallery-custom-arrow-prev {
    left: 40%;
    padding-top: 90px;
  }
  .gallery-custom-arrow-next {
    right: 34%;
    padding-top: 90px;
  }
}

@media (min-width: 399px) and (max-width: 589px) {
  .sideimgsml {
    width: 100%;
  }
  .gallery-custom-arrow-prev {
    left: 35%;
    padding-top: 25px;
  }
  .gallery-custom-arrow-next {
    right: 27%;
    padding-top: 25px;
  }
}
@media (min-width: 290px) and (max-width: 398px){
.gallery-custom-arrow-prev {
  left: 29%;
  padding-top: 20px;
}
.gallery-custom-arrow-next {
  right: 17%;
  padding-top: 20px;
}}
/* @media (min-width: 1024px) and (max-width: 1200px) {
  .gallery-custom-arrow-prev {
    left: 42%;
    padding-top: 30px;
  }
  .gallery-custom-arrow-next {
    right: 40%;
    padding-top: 30px;
  }
} */
