@import '../../styles/abstracts/variables'
@import '../../styles/abstracts/helpers'

.card-carousel-container
    width: 90%
    margin: 0 auto

.card-new
    padding: 25px
    background: #fff
    border-radius: 24px
    box-shadow: 1px 1px 15.2px 0px #00000026
    transition: transform 0.3s ease
    position: relative
    min-height: 320px

.card-container-new
    padding: 10px 20px
    box-sizing: border-box
    width: 365px !important
    height: 371px
    margin: 0px 4%
    +sm
        width: 100% !important
        margin: 0px auto
    +xs 
        padding: 10px 10px

.card-content-holder
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 25px

.span-heading
    font-size: 24px
    font-weight: 700
    line-height: 29px
    color: $black
    text-align: center

.span-desc
    font-size: 16px
    font-weight: 400
    line-height: 20px
    color: $black
    text-align: justify
    width: 245px
    hyphens: auto
    word-spacing: 0.2em
    +sm
        width:100%

